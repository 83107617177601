export const WebViewStringParams = ['authToken', 'baseUrl', 'env', 'eventPublicId', 'lang', 'locale'] as const;
export const WebViewNumberParams = ['appId', 'eventId', 'userId'] as const;
export const WebViewBooleanParams = ['embedded'] as const;

export type TWebViewStringParams = typeof WebViewStringParams[number];
type TWebViewNumberParams = typeof WebViewNumberParams[number];
type TWebViewBooleanParams = typeof WebViewBooleanParams[number];

export const webViewStringParamGuard = (param: unknown): param is TWebViewStringParams =>
  WebViewStringParams.some((item) => item === param);
export const webViewNumberParamGuard = (param: unknown): param is TWebViewNumberParams =>
  WebViewNumberParams.some((item) => item === param);
export const webViewBooleanParamGuard = (param: unknown): param is TWebViewBooleanParams =>
  WebViewBooleanParams.some((item) => item === param);

export type TWebViewOther = {
  [key in string]?: string;
};

export type TWebViewAuth =
  | ({
      [key in TWebViewStringParams]?: string;
    } & {
      [key in TWebViewNumberParams]?: number;
    } & {
      [key in TWebViewBooleanParams]?: boolean;
    })
  | null;

export interface IWebView {
  auth: TWebViewAuth;
  other: TWebViewOther;
}

import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Player } from 'components';
import { usePlayerContext } from '@vime/react';
import LazyLoad from 'react-lazy-load';
import { useIntl } from 'react-intl';
import { TCatalogMedia } from 'types';
import { IVideo } from 'types/coursesApiTypes';
import { DomainStore, UIStore } from 'store';
import { getRootCatalogIdFromCatalogParam, sendAnalyticsEvent, useAppNavigator } from 'utils';
import {
  StyledPlayIcon,
  PreloadImage,
  PreloadWrapper,
  DurationLabel,
  ContentTypeLabel,
  StyledVideoIcon,
  IconOverlay,
  VideoName,
  VideoIconWrapper,
  PlayerWrapper,
} from './Video.styles';
import messages from './messages';

interface Props {
  video: TCatalogMedia;
}

const Video: React.FC<Props> = ({ video }) => {
  const intl = useIntl();
  const { fetchVideo } = DomainStore.catalog;
  const { setActivePlayerRef } = UIStore.layout;
  const [videoData, setVideoData] = useState<IVideo | null>(null);
  const [analyticsEventSended, setAnalyticsEventSended] = useState(false);
  const [isVideoResized, setVideoResized] = useState(false);
  const {
    params: { catalog: catalogParam, trackId },
  } = useAppNavigator();

  const playerRef = useRef<HTMLVmPlayerElement>(null);
  const playerWrapperRef = useRef<HTMLDivElement>(null);
  const [isPiPActive] = usePlayerContext(playerRef, 'isPiPActive', false);

  const getVideoData = async () => {
    const data = await fetchVideo(video.video.id, trackId);
    if (data) {
      setVideoData(data);
    }
  };

  const togglePlay = () => {
    if (playerRef.current) {
      if (playerRef.current.playing) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
    }
  };

  const onPlay = () => {
    setActivePlayerRef(playerRef.current);

    if (!analyticsEventSended && catalogParam) {
      sendAnalyticsEvent('Catalog: Play video', {
        media_type: 'hls',
        media_id: video.video.id,
        media_name: video.name,
        catalogId: getRootCatalogIdFromCatalogParam(catalogParam),
      });
      setAnalyticsEventSended(true);
    }
  };

  // eslint-disable-next-line
  const onError = (e: CustomEvent<any>) => {
    if (videoData) {
      // HACK: Меняем размер контейнера видео для правки ошибки загрузки манифеста hls
      if (e.detail?.data?.details === 'manifestLoadError' && !isVideoResized) {
        setVideoResized(true);
      }
    }
  };

  useEffect(() => {
    const ref = playerRef.current;
    return () => {
      if (isPiPActive) {
        ref?.exitPiP();
      }
    };
  }, [isPiPActive]);

  const config = {
    // debug: true,
  };

  return (
    <>
      <PreloadWrapper ref={playerWrapperRef} onClick={videoData ? togglePlay : getVideoData} resized={isVideoResized}>
        <PlayerWrapper
          style={{
            visibility: videoData ? 'visible' : 'hidden',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <LazyLoad>
            <Player
              type="hls"
              src={videoData?.url || ''}
              config={config}
              playerRef={playerRef}
              onPlay={onPlay}
              onError={onError}
              language={UIStore.locale}
            />
          </LazyLoad>
        </PlayerWrapper>
        {video.fullLogoImage ? (
          <PreloadImage src={video.fullLogoImage.url} width="100%" preview={false} />
        ) : (
          <VideoIconWrapper>
            <StyledVideoIcon />
          </VideoIconWrapper>
        )}
        <ContentTypeLabel>{intl.formatMessage(messages.video)}</ContentTypeLabel>
        {video.video.duration && (
          <DurationLabel>
            {video.video.duration} {intl.formatMessage(messages.min)}
          </DurationLabel>
        )}
        <IconOverlay>
          <StyledPlayIcon />
        </IconOverlay>
      </PreloadWrapper>
      {video.name && <VideoName ellipsis={{ rows: 4 }}>{video.name}</VideoName>}
    </>
  );
};
export default observer(Video);

import ky from 'ky';
import { makeAutoObservable } from 'mobx';
import { KyInstance as IKy } from 'ky/distribution/types/ky';
import { getMobileApiHost } from 'env';
import store from 'store';
import webViewStore from 'store/WebView';
import { ApiHeaders, StatusCodes, REQUEST_TIMEOUT, USER_METRICS_URL } from './constants';
import { makeRequest } from './request';

type TLearningTracksHeaders = { [ApiHeaders.xTrackId]: string } | Record<string, never>;

export const setLearningTracksHeaders = (trackId?: string): TLearningTracksHeaders => {
  return trackId ? { [ApiHeaders.xTrackId]: trackId } : {};
};

class Api {
  public publicId = '';

  public token = '';

  public prefixUrl = '';

  constructor(publicId?: string) {
    makeAutoObservable(this);

    this.publicId = publicId || '';
    this.prefixUrl = getMobileApiHost();
  }

  private get publicApi(): IKy {
    return ky.create({
      prefixUrl: this.prefixUrl,
      headers: {
        [ApiHeaders.xPublicEventId]: this.publicId,
      },
      timeout: REQUEST_TIMEOUT,
      throwHttpErrors: false,
      hooks: {
        afterResponse: [
          (_request, _options, response) => {
            if (
              !response.ok &&
              response.status !== StatusCodes.Unauthorized &&
              response.status !== StatusCodes.NotValid &&
              response.status !== StatusCodes.Blocked &&
              !_request.url.includes(USER_METRICS_URL)
            ) {
              store.UIStore.notifications.setNotification({
                type: 'error',
                closable: true,
              });
            }
          },
        ],
      },
    });
  }

  private get privateApi(): IKy {
    return this.publicApi.extend({
      headers: {
        [ApiHeaders.authorization]: this.token,
      },
      hooks: {
        afterResponse: [
          (_request, _options, response) => {
            if (response && !response.ok && response.status === StatusCodes.Unauthorized) {
              store.DomainStore.clearAuthData();
            }
            if (response && response.status === StatusCodes.NotFound) {
              store.UIStore.layout.onNotFoundStatusReceived();
            }
            if (response && response.status === StatusCodes.Forbidden) {
              store.UIStore.layout.show403placeholder();
            }
          },
        ],
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private get mobileApi(): IKy {
    return ky.create({
      prefixUrl: webViewStore.auth?.baseUrl,
      headers: webViewStore.auth
        ? {
            [ApiHeaders.authorization]: webViewStore.auth.authToken,
            [ApiHeaders.eventId]: webViewStore.auth.eventId?.toString(),
            [ApiHeaders.acceptLanguage]: webViewStore.auth.lang,
            [ApiHeaders.xUserId]: webViewStore.auth.userId?.toString(),
            [ApiHeaders.xUserContentLanguage]: webViewStore.auth.locale,
            [ApiHeaders.xWebEventRocks]: webViewStore.webEventRocks.toString(),
          }
        : undefined,
      timeout: REQUEST_TIMEOUT,
      throwHttpErrors: false,
    });
  }

  get privateRequest() {
    return makeRequest(this.privateApi);
  }

  get publicRequest() {
    return makeRequest(this.publicApi);
  }

  get mobileRequest() {
    return makeRequest(this.mobileApi);
  }
}

const api = new Api();

export default api;

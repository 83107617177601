export enum Locales {
  RU = 'ru',
  EN = 'en',
  BG = 'bg',
  DE = 'de',
  FR = 'fr',
  KK = 'kk',
  UK = 'uk',
}

export enum LocalizationCodes {
  RU = 'ru-RU',
  EN = 'en-US',
  BG = 'bg-BG',
  DE = 'de-DE',
  FR = 'fr-FR',
  KK = 'kk-KZ',
  UK = 'uk-UA',
}

export const localizationCodeGuard = (code: unknown): code is LocalizationCodes => {
  return Object.values(LocalizationCodes).includes(code as LocalizationCodes);
};

export const localeGuard = (locale: unknown): locale is Locales => {
  return Object.values(Locales).includes(locale as Locales);
};

export const LOCALIZATION_CODES_MAP = {
  [Locales.RU]: LocalizationCodes.RU,
  [Locales.EN]: LocalizationCodes.EN,
  [Locales.BG]: LocalizationCodes.BG,
  [Locales.DE]: LocalizationCodes.DE,
  [Locales.FR]: LocalizationCodes.FR,
  [Locales.KK]: LocalizationCodes.KK,
  [Locales.UK]: LocalizationCodes.UK,
};

export const DEFAULT_LOCALE = Locales.RU;
export const DEFAULT_LOCALIZATION_CODE = LOCALIZATION_CODES_MAP[DEFAULT_LOCALE];

const LOCALES_MAP = {
  [LocalizationCodes.RU]: Locales.RU,
  [LocalizationCodes.EN]: Locales.EN,
  [LocalizationCodes.BG]: Locales.BG,
  [LocalizationCodes.DE]: Locales.DE,
  [LocalizationCodes.FR]: Locales.FR,
  [LocalizationCodes.KK]: Locales.KK,
  [LocalizationCodes.UK]: Locales.UK,
};

export const getLocaleByLocalizationCode = (lang: string): Locales => {
  if (localizationCodeGuard(lang)) {
    return LOCALES_MAP[lang];
  }

  return DEFAULT_LOCALE;
};

export const getLocalizationCodeByLocale = (locale: string): LocalizationCodes => {
  if (localeGuard(locale)) {
    return LOCALIZATION_CODES_MAP[locale];
  }

  return DEFAULT_LOCALIZATION_CODE;
};

const currentBrowserLocale = navigator.language.toLowerCase().substring(0, 2);
const locales = Object.values(Locales);
export const currentLocale = locales.find((locale) => locale === currentBrowserLocale) || Locales.EN;

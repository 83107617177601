/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { types, cast, flow, getRoot } from 'mobx-state-tree';
import { MRating, MRule, MPrize, MRatingDetail, MRatingGroups } from 'models';
import { TRatingsResponse, TRatingDetailsResponse, TSearchedRatingsResponse } from 'types/gamificationApiTypes';
import { TStore } from 'types';
import { GamificationTypes } from 'types/gamificationTypes';
import { RATINGS_PAGE_SIZE } from 'views/Gamification/constants';
import { gamificationApi } from 'api';
import { isTalentRocksApp } from 'env';
import webViewStore from 'store/WebView';
import { DEFAULT_LOCALIZATION_CODE } from '_constants/Locales';
import { TGamificationResponse } from '../../types/gamificationApiTypes';

const Gamification = types
  .model({
    gamificationType: types.enumeration<GamificationTypes>('GamificationType', Object.values(GamificationTypes)),
    ratings: types.array(MRating),
    ratingGroups: types.maybeNull(types.array(MRatingGroups)),
    ratingDetails: types.array(MRatingDetail),
    authorizedUserRating: types.maybeNull(MRating),
    rules: types.array(MRule),
    rulesHeader: '',
    pointsLabel: '',
    prizes: types.array(MPrize),
    currentGroupId: types.maybeNull(types.number),
  })
  .views((self) => ({
    get allCount() {
      return self.ratings.length;
    },
    getPrize(id: number) {
      return self.prizes.find((prize) => prize.id === id) || null;
    },
    get gamificationUIStore() {
      const { gamification, groupGamification } = getRoot<TStore>(self).UIStore;
      return self.gamificationType === GamificationTypes.GROUP ? groupGamification : gamification;
    },
    get themeUIStore() {
      return getRoot<TStore>(self).UIStore.theme;
    },
  }))
  .actions((self) => ({
    setCurrentGroupId: (id: number | null) => {
      self.currentGroupId = id;
    },
    fetchRatings: flow(function* fetchRatings(pageSize: number = RATINGS_PAGE_SIZE) {
      self.gamificationUIStore.setRatingsLoading(true);
      const eventId = webViewStore.auth?.eventId ?? 0;
      const mobileLocale = webViewStore.auth?.locale ?? DEFAULT_LOCALIZATION_CODE;

      const { data, hasError }: TRatingsResponse = isTalentRocksApp
        ? yield gamificationApi.getRatings(self.gamificationType, 0, pageSize, self.currentGroupId)
        : yield gamificationApi.getMobileRatings(
            self.gamificationType,
            0,
            pageSize,
            eventId,
            mobileLocale,
            self.currentGroupId,
          );

      if (!hasError && data) {
        self.ratings = cast(data.ratings ? data.ratings : []);
        self.ratingGroups = cast(data.ratingGroups);
        self.authorizedUserRating = data.authorizedUserRating;
      }
      self.gamificationUIStore.setRatingsLoading(false);
    }),
  }))
  .actions((self) => ({
    fetchSearchedRatings: flow(function* fetchSearchedRatings(search: string) {
      self.gamificationUIStore.setRatingsLoading(true);
      const eventId = webViewStore.auth?.eventId ?? 0;
      const mobileLocale = webViewStore.auth?.locale ?? DEFAULT_LOCALIZATION_CODE;

      const { data, hasError }: TSearchedRatingsResponse = isTalentRocksApp
        ? yield gamificationApi.getSearchedRatings(self.gamificationType, search, self.currentGroupId)
        : yield gamificationApi.getMobileSearchedRatings(
            self.gamificationType,
            search,
            eventId,
            mobileLocale,
            self.currentGroupId,
          );

      if (!hasError && data) {
        self.ratings = cast(data.ratings);
      }
      self.gamificationUIStore.setRatingsLoading(false);
    }),
    fetchMoreRatings: flow(function* fetchMoreRatings(page: number, pageSize: number = RATINGS_PAGE_SIZE) {
      self.gamificationUIStore.setRatingsLoading(true);
      const eventId = webViewStore.auth?.eventId ?? 0;
      const mobileLocale = webViewStore.auth?.locale ?? DEFAULT_LOCALIZATION_CODE;

      const { data, hasError }: TRatingsResponse = isTalentRocksApp
        ? yield gamificationApi.getRatings(self.gamificationType, page, pageSize, self.currentGroupId)
        : yield gamificationApi.getMobileRatings(
            self.gamificationType,
            page,
            pageSize,
            eventId,
            mobileLocale,
            self.currentGroupId,
          );

      if (!hasError && data) {
        self.ratings = cast([...self.ratings, ...data.ratings]);
      }
      self.gamificationUIStore.setRatingsLoading(false);
    }),
    fetchRatingsDetails: flow(function* fetchRatingsDetails() {
      self.gamificationUIStore.setPersonalRatingsDetailsLoading(true);
      if (!self.ratings.length && !self.authorizedUserRating) yield self.fetchRatings();
      const eventId = webViewStore.auth?.eventId ?? 0;
      const mobileLocale = webViewStore.auth?.locale ?? DEFAULT_LOCALIZATION_CODE;

      const { data, hasError }: TRatingDetailsResponse = isTalentRocksApp
        ? yield gamificationApi.getPersonalRatingsDetails(self.currentGroupId)
        : yield gamificationApi.getMobilePersonalRatingsDetails(eventId, mobileLocale, self.currentGroupId);

      if (!hasError && data) {
        self.ratingDetails = cast(data.pointsDetailRecords);
      }
      self.gamificationUIStore.setPersonalRatingsDetailsLoading(false);
    }),
    fetchGamificationData: flow(function* fetchGamificationData() {
      self.gamificationUIStore.setGamificationDataLoading(true);
      const eventId = webViewStore.auth?.eventId ?? 0;
      const mobileLocale = webViewStore.auth?.locale ?? DEFAULT_LOCALIZATION_CODE;

      const { data, hasError }: TGamificationResponse = isTalentRocksApp
        ? yield gamificationApi.getGamificationData(self.gamificationType)
        : yield gamificationApi.getMobileGamificationData(self.gamificationType, eventId, mobileLocale);

      if (!hasError && data) {
        self.rulesHeader = data.rulesHeader;
        self.pointsLabel = data.pointsLabel;
        self.rules = cast(data.rules);
        self.prizes = cast(data.prizes);

        if (!isTalentRocksApp) {
          if (data.mainColor) {
            self.themeUIStore.setAccentColor(data.mainColor);
          }
        }
      }
      self.gamificationUIStore.setGamificationDataLoading(false);
    }),
  }));

export default Gamification;

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Spinner from 'components/Spinner/Spinner';
import webViewStore from 'store/WebView';
import { ProblemPlaceholder } from 'components';

const WebViewProvider: React.FC = ({ children }) => {
  const { isReady, isSuccess, initDate } = webViewStore;

  useEffect(() => {
    initDate();
  }, [initDate]);

  if (!isReady) {
    return <Spinner fullscreen />;
  }

  return isSuccess ? <>{children}</> : <ProblemPlaceholder type="error404" shouldHideButton />;
};

export default observer(WebViewProvider);

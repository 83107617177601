import { TRatingsResponse, TRatingDetailsResponse, TGamificationResponse } from 'types/gamificationApiTypes';
import { GamificationTypes } from 'types/gamificationTypes';
import { SDK } from 'utils';
import webViewStore from 'store/WebView';
import api from './api';

interface ISearchParams {
  skip: number;
  take: number;
  groupId?: number;
}

const getRatings = (
  type: GamificationTypes,
  page: number,
  pageSize: number,
  groupId: number | null,
): Promise<TRatingsResponse> => {
  const searchParams: ISearchParams = {
    skip: page * pageSize,
    take: pageSize,
  };
  if (groupId) {
    searchParams.groupId = groupId;
  }
  return api.privateRequest('get', `api/web/tlrocks/ratings/${type}`, {
    searchParams: searchParams as unknown as Record<string, string | number | boolean>,
  });
};

interface ISearchRatingsParams {
  search: string;
  groupId?: number;
}

const getMobileRatings = (
  type: GamificationTypes,
  page: number,
  pageSize: number,
  eventId: number,
  locale: string,
  groupId: number | null,
): Promise<TRatingsResponse> =>
  api.mobileRequest(
    'get',
    SDK.haveSDK || webViewStore.other.apiType === 'admin'
      ? `networking/api/ratings/${type}`
      : `api/mobile/rocks/ratings/${type}`,
    {
      searchParams: {
        eventId,
        skip: page * pageSize,
        take: pageSize,
        locale,
        ...(groupId ? { groupId } : {}),
      },
    },
  );

const getSearchedRatings = (
  type: GamificationTypes,
  search: string,
  groupId: number | null,
): Promise<TRatingsResponse> => {
  const searchParams: ISearchRatingsParams = { search };
  if (groupId) {
    searchParams.groupId = groupId;
  }
  return api.privateRequest('get', `api/web/tlrocks/ratings/${type}/search`, {
    searchParams: searchParams as unknown as Record<string, string | number | boolean>,
  });
};

const getMobileSearchedRatings = (
  type: GamificationTypes,
  search: string,
  eventId: number,
  locale: string,
  groupId: number | null,
): Promise<TRatingsResponse> =>
  api.mobileRequest(
    'get',
    SDK.haveSDK || webViewStore.other.apiType === 'admin'
      ? `networking/api/ratings/${type}/search`
      : `api/mobile/rocks/ratings/${type}/search`,
    {
      searchParams: {
        search,
        eventId,
        locale,
        ...(groupId ? { groupId } : {}),
      },
    },
  );

const getGamificationData = (type: GamificationTypes): Promise<TGamificationResponse> =>
  api.privateRequest('get', `api/web/tlrocks/gamification/${type}`);

const getMobileGamificationData = (
  type: GamificationTypes,
  eventId: number,
  locale: string,
): Promise<TGamificationResponse> =>
  api.mobileRequest('get', `api/public/gamification/${type}`, {
    searchParams: {
      eventId,
      locale,
    },
  });

const getPersonalRatingsDetails = (groupId: number | null): Promise<TRatingDetailsResponse> => {
  const searchParams: { groupId?: number } = {};
  if (groupId) {
    searchParams.groupId = groupId;
  }
  return api.privateRequest('get', `api/web/tlrocks/ratings/personal/detail`, {
    searchParams: searchParams as unknown as Record<string, string | number | boolean>,
  });
};

const getMobilePersonalRatingsDetails = (
  eventId: number,
  locale: string,
  groupId: number | null,
): Promise<TRatingDetailsResponse> =>
  api.mobileRequest(
    'get',
    SDK.haveSDK || webViewStore.other.apiType === 'admin'
      ? `networking/api/ratings/personal/detail`
      : `api/mobile/rocks/ratings/personal/detail`,
    {
      searchParams: { eventId, locale, ...(groupId ? { groupId } : {}) },
    },
  );

export default {
  getRatings,
  getMobileRatings,
  getPersonalRatingsDetails,
  getMobilePersonalRatingsDetails,
  getSearchedRatings,
  getMobileSearchedRatings,
  getGamificationData,
  getMobileGamificationData,
};

import en from './en.json';
import ru from './ru.json';
import bg from './bg.json';
import de from './de.json';
import fr from './fr.json';
import kk from './kk.json';
import uk from './uk.json';

export default {
  en,
  ru,
  bg,
  de,
  fr,
  kk,
  uk,
};

export enum ScreenNames {
  CATALOG = 'Catalog',
  POLL_SCREEN = 'Polls: Poll screen',
  POLL_FINAL_SCREEN = 'Polls: Final screen',
  POLL_RESULTS = 'Polls: Results',
  MY_POLLS = 'Polls: My Polls',
  GROUP_GAMIFICATION = 'Group gamification',
  PERSONAL_GAMIFICATION = 'Personal gamification',
  COURSE = 'Course',
  MY_COURSES = 'My courses',
  COURSE_STAGE = 'Course stage',
  MY_TEAM = 'My team',
  AUTH_SIGN_IN = 'Auth: Sign in',
  AUTH_PASSWORD_RECOVERY = 'Auth: Password recovery',
  AUTH_REGISTRATION = 'Auth: Registration',
  SCHEDULE = 'Schedule',
  MY_LEARNING_TRACKS = 'Track: My tracks',
  WEBVIEW = 'Web-view',
  MY_TASKS = 'Task: My tasks',
  TASK_FORM = 'Task: Form',
  TASK_COMPLETED = 'Task: Completed',
  TASK_IN_REVIEW = 'Task: In review',
  TASK_FOR_REVISION = 'Task: For revision',
  TASK_MY_ANSWERS = 'Task: My answers',
  TASK_FEEDBACK = 'Task: Feedback',
  MY_TRACKS = 'Track: My tracks',
  TRACK_STEPS = 'Track: Steps',
  TRACK_DESCRIPTION = 'Track: Description',
}

export enum LearningTrackSources {
  MY_TRACKS = 'my tracks',
  DASHBOARD = 'dashboard',
}

export enum CourseSources {
  TRACK = 'track',
  MY_COURSES = 'my courses',
  DASHBOARD = 'dashboard',
}

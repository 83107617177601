import { types } from 'mobx-state-tree';

export const MRating = types.model({
  id: types.number,
  fullName: types.string,
  imagePath: types.maybeNull(types.string),
  points: types.number,
  rank: types.number,
});

export const MRatingGroups = types.model({
  id: types.maybeNull(types.number),
  name: types.string,
});

export const MRule = types.model({
  title: types.string,
  hint: types.maybeNull(types.string),
  points: types.string,
  order: types.number,
});

export const MPrize = types.model({
  id: types.number,
  header: types.maybeNull(types.string),
  subheader: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  imagePath: types.maybeNull(types.string),
  order: types.number,
});

export const MRatingDetail = types.model({
  title: types.string,
  points: types.number,
  numberOfTimes: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});

import { useCallback, useEffect, useRef, useState } from 'react';

type SizeState = { width: number; height: number };

const useWindowSize = (): SizeState => {
  const [state, setState] = useState<SizeState>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const debounceTimer = useRef<number | undefined>();

  const debounce = useCallback((callback: () => void, time: number) => {
    window.clearTimeout(debounceTimer.current);
    debounceTimer.current = window.setTimeout(callback, time);
  }, []);

  useEffect((): (() => void) | void => {
    const handler = () => {
      debounce(() => {
        setState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 500);
    };

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [debounce]);

  return state;
};

export default useWindowSize;
